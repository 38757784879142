/**
 * Font face mixin
 * fork from https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
 */
@mixin font-face($name, $path, $display: swap, $weight: null, $style: null, $exts: woff2 woff) {
    $src: null;

    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_')
    );

    $formats: (
        otf: 'opentype',
        ttf: 'truetype'
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url('#{quote($path + "." + $extmod)}') format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-display: $display;
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

/**
 * Element reset
 */
@mixin reset {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    text-decoration: none;
    color: currentColor;
    line-height: 1;
}

/**
 * Fit container
 * Remove extra padding
 */
@mixin fit-container {
    margin-left: calc(var(--gutter) * -1);
    margin-right: calc(var(--gutter) * -1);
}

/**
 * Typo
 */
@mixin title-2 {
    font-family: var(--font-saritha);
    font-weight: 400;
    font-size: prem(46);

    @media (--sm) {
        font-size: prem(56);
    }

    @media (--md) {
        font-size: prem(72);
    }
}

@mixin title-3 {
    font-family: var(--font-saritha);
    font-weight: 400;
    font-size: prem(46);

    @media (--md) {
        font-size: prem(48);
    }
}

@mixin text-paragraph {
    font-size: prem(18);
    line-height: 1.4;
}

